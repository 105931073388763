import React from 'react';
import * as fetchClient from '@kathondvla/sri-client/fetch-sri-client';
import CurriculaSelectorWrapper from './CurriculaSelectorWrapper';
import { interceptFactory } from '@kathondvla/fetch-oauth-interceptor';
import { api, cachedApi, oauth } from './config/settings';

interceptFactory({
  urlPatterns: [
      new RegExp(api, 'g'),
  ],
  oauth,
  onUserChanged: (newUser) => {
      console.log('OAUTH:', 'user changed', newUser);
      if (newUser) {
          // setUserHref('/persons/' + newUser.uuid);
      } else {
          // setUserHref('');
      }
  },
  onLoggedOut: () => {
      console.log('OAUTH:', 'we appear to be logged out');
  },
});

const sriClient = fetchClient({ name: 'api', baseUrl: api });
const sriClientCached = fetchClient({ name: 'cachedApi', baseUrl: cachedApi })

const apiConfig = {
  sriClient,
  sriClientCached
};

function App() {
  return (
    <div className="App">
      <CurriculaSelectorWrapper apiConfig={apiConfig} />
    </div>
  );
}

export default App;
