import { CurriculaSelector } from '@kathondvla/curricula-selector';
import useMessage from '@rottitime/react-hook-message-event';
import React, { useEffect, useState } from 'react';
import useLoggedInUser from './hooks/useLoggedInUser';
import { oauth } from './config/settings';

const CurriculaSelectorWrapper = ({ apiConfig }) => {
    const userHref = useLoggedInUser();
    const [initialSelection, setInitialSelection] = useState([]);
    const [submitButtonLabel, setsubmitButtonLabel] = useState(undefined);
    const { sendToParent } = useMessage('');
    const postMessageEnabled = !!window.opener;
    useMessage('SELECT_CURRICULA', (send, payload) => {
        console.log('received selected curricula', payload);
        setInitialSelection(payload);
    })

    useEffect(() => {
        if (postMessageEnabled) {
            //we're in postmessage mode
            console.log('send LOADED');
            sendToParent({ type: 'LOADED' });
        } else {
            // we're in standalone mode.
            setsubmitButtonLabel('📋 Kopieer');
            setInitialSelection([]);
        }
    }, [])

    // example how to receive the pasted code
    // useEffect(() => {
    //     const pasteEventFunction = (event) => {
    //         event.preventDefault();
    //         const paste = (event.clipboardData || window.clipboardData).getData('text');

    //         // const guidsSimpler = paste.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g);
    //         const hrefsSimpler =paste.match(/(\/\w+)+\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g);

    //         console.log('hrefs', hrefsSimpler);
    //         setInitialSelection(hrefsSimpler);
    //     };
    //     window.addEventListener('paste', pasteEventFunction);

    //     return () => { window.removeEventListener('paste', pasteEventFunction) };
    // }, [])


    const sendSelection = async (items) => {
        if (postMessageEnabled) {
            console.log('submitted items', items)
            sendToParent({ type: 'SELECTION_DONE', payload: items })
        } else {
            const text = items.map(i => `${i.title} [${i.href}]`).join('\r\n');
            const html = items.map(i => `${i.title} <span style="font-size:1px; font-family:courier new; color: white;">[${i.href}]</span>`).join('<br />');

            let clipboardItem;
            try {
                clipboardItem = new ClipboardItem({
                    'text/html': new Blob([html],
                        { type: 'text/html' }),
                    'text/plain': new Blob([text],
                        { type: 'text/plain' })
                });
            } catch (ex) {
                if (ex instanceof ReferenceError) {
                    // firefox doesn't support ClipboardItem
                } else {
                    throw ex;
                }
            }

            try {
                if (clipboardItem) {
                    await navigator.clipboard.write([clipboardItem]);
                } else {
                    await navigator.clipboard.writeText(text);
                }
                console.log('Copying to clipboard was successful!', text);
                setsubmitButtonLabel('✅ Gekopieerd');
            } catch (ex) {
                setsubmitButtonLabel('⚠️ Gefaald');
                console.error('Could not copy text: ', ex);
            } finally {
                setTimeout(() => {
                    setsubmitButtonLabel('📋 Kopieer');
                }, 2000)
            }
        }
    };

    const cancelSelection = (items) => {
        if (postMessageEnabled) {
            console.log('selection cancelled');
            sendToParent({ type: 'SELECTION_CANCELLED' })
        }
    };

    useEffect(() => {
        console.log('user', userHref);
    }, [userHref])

    useEffect(() => {
        console.log('initialSelection changed to ', initialSelection);
    }, [initialSelection])

    const loginUrl = `${oauth.authenticate}?scope=${oauth.scope}&response_type=none&client_id=${oauth.clientID}&redirect_uri=${oauth.redirectUri}&state=${encodeURIComponent(window.self.location.origin)}`;

    return (
        <div>
            <CurriculaSelector
                // themes from the post message sent from tink
                initialSelection={initialSelection}
                // from the settings
                apiConfig={apiConfig}
                // user (also from the post message?)
                userHref={userHref}
                // send the result back to tink
                handleSubmit={(items) => sendSelection(items)}
                // button `annuleer` closes the tab?
                handleClose={() => cancelSelection()}
                submitButtonLabel={submitButtonLabel}
                // from the settings
                loginUrl={loginUrl}
            />
        </div>
    );
}

export default CurriculaSelectorWrapper;