import { oauth } from '../config/settings';
import { useState, useEffect } from 'react';
import * as fetchClient from '@kathondvla/sri-client/fetch-sri-client';

const useLoggedInUser = () => {
    const [userHref, setUserHref] = useState(null);

    useEffect(() => {
        const oauthClientConfig = { name: 'oauth', baseUrl: oauth.oauthURL };
        const oauthClient = fetchClient(oauthClientConfig);


        const getMe = async () => {
            try {
                const me = await oauthClient.get('/me', undefined, { credentials: 'include' });
                setUserHref('/persons/' + me.uuid);
            } catch (err) {
                // console.log(err);
            }
        }
        getMe();
    }, []);

    return userHref;
}

export default useLoggedInUser;